import { Alpine } from "../../../vendor/livewire/livewire/dist/livewire.esm";
import "./Tracker.js"

Alpine.store('readArticles', {
    read: [],
    total: [],
    notRead: [],
    init() {
        const stored = localStorage.getItem('readArticles');
        // If stored value exists and is not empty, split by pipe; otherwise use an empty array..
        this.read = stored ? stored.split('|') : [];
    },
    updateLocalStorage() {
        // Enforce a maximum length of 20 IDs.
        if (this.read.length > 20) {
            this.read = this.read.slice(-20);
        }
        localStorage.setItem('readArticles', this.read.join('|'));
    },
    updateNotRead() {
        // Calculate the difference between the total IDs and the read IDs.
        if (this.total && Array.isArray(this.total)) {
            this.notRead = this.total.filter(id => !this.read.includes(id));
        }
    },
    markArticleRead(id) {
        if (!this.read.includes(id)) {
            this.read.push(id);
            this.updateLocalStorage();
            this.updateNotRead();
        }
    }
});

Alpine.data('readingTracker', () => ({
    articles: [],
    init() {
        // Grab all <article> elements on the page.
        this.articles = document.getElementsByTagName('article');
        // Once the DOM is ready, attach the tracker to the first 8 articles.
        document.addEventListener('DOMContentLoaded', () => {
            let totalIds = [];
            for (let i = 0; i < Math.min(13, this.articles.length); i++) {
                const article = this.articles[i];
                const trackingId = article.dataset.trackingId;

                // Only proceed if the trackingId is defined.
                if (trackingId) {
                    // Set the article's id attribute for linking purposes.
                    article.setAttribute('id', trackingId);
                    // Set this article to use the "tracker" Alpine data component.
                    article.setAttribute('x-data', 'tracker');
                    article.setAttribute('x-intersect.threshold.20', 'startTimer()');
                    article.setAttribute('x-intersect:leave', 'stopTimer()');
                    // Collect the tracking id.
                    totalIds.push(trackingId);
                } else {
                    console.warn(`Article at index ${i} is missing a data-tracking-id attribute.`);
                }
            }
            // Save total IDs to the store and update notRead.
            Alpine.store('readArticles').total = totalIds;
            Alpine.store('readArticles').updateNotRead();
        });


    }
}));