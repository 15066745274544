import { Alpine } from "../../../vendor/livewire/livewire/dist/livewire.esm";

Alpine.data('tracker', () => ({
    id: null,
    timer: null,
    init() {
        // Set the tracking id from the element's data attribute..
        this.id = this.$el.dataset.trackingId;
    },
    startTimer() {
        console.log('started timer')
        // Start an 8-second timer to mark the article as read.
        this.timer = setTimeout(() => {
            Alpine.store('readArticles').markArticleRead(this.id);
        }, 3000);
    },
    stopTimer() {
        // Cancel the timer if the user leaves before 8 seconds.
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
        }
    }
}));
