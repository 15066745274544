import { Alpine } from "../../../vendor/livewire/livewire/dist/livewire.esm";

Alpine.data('slideShow', () => ({
    slideIndex: 0,
    player: null,
    slides: [],
    delay: 20000,

    // Initialize slides array (for counting) and start auto-advance.
    initSlides() {
        // Grab all slide elements (for example, to check for videos).
        this.slides = Array.from(document.getElementsByClassName('slide'));
        if (this.slides.length) {
            // Start auto advancing slides.
            this.next();
        }
    },

    // Advance the slide index and the transform will update automatically.
    advanceSlide() {
        this.slideIndex = (this.slideIndex + 1) % this.slides.length;
    },

    // Advance automatically based on whether there's a video.
    next() {
        const currentSlide = this.slides[this.slideIndex];
        const hasVideo = currentSlide.querySelector('video') !== null;

        if (hasVideo) {
            if (this.player) {
                // Wait for the video to complete.
                this.player.once('complete', () => {
                    this.advanceSlide();
                    setTimeout(() => this.next(), this.delay);
                });
            } else {
                // Poll until the player is available.
                setTimeout(() => this.next(), this.delay);
            }
        } else {
            // Non-video slide: auto-advance after the delay.
            setTimeout(() => {
                this.advanceSlide();
                this.next();
            }, this.delay);
        }
    },

    // Set the video player from a given uid.
    setPlayer(uid) {
        let player = window.frop_players[uid];
        if (player) {
            this.player = player;
            this.player.play()
        }
    },

}));
